import React, { memo, useMemo, useState } from 'react';
import useFilterData from '../../../hooks/useFilterData';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Icon from '../../icon/Icon';
import Card, { CardBody, CardHeader, CardTitle } from '../../bootstrap/Card';
import Money from '../../../helpers/utils/money/money';

const TableCustomPdf = ({ columns, rows, keyExtractor, icon, title, initPerPage, fontSize }) => {
	const totalItensPdf = useMemo(() => {
		if (!rows) return 1000
		return rows.length
	}, [rows]);
	const [search, setsearch] = useState('');
	const dataFilter = useFilterData(rows, search)
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(totalItensPdf);
	const { items, requestSort, getClassNamesFor } = useSortableData(dataFilter);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const columnsRender = useMemo(() => columns.filter(c => !c.hidden), [columns])
	let totalItems = items.reduce((prev, curr) => prev + curr.totalGeral, 0);


	const styles = {
		fontSize: fontSize,
		textAlign: 'center',
		borderColor: '#00000066',
		color: '#00000066',
		fontWeight: 'bold',
		borderBottomWidth: '1px',
		verticalAlign: 'middle'
	}

	const styleText = {
		color: '#000000B2',
	}
	const styleTotal = {
		color: '#00000066'
	}


	return (
		<Card
			shadow="sm"
		>
			<CardHeader>
				<CardTitle className="d-flex justify-content-center  align-content-center gap-2 ">
					<Icon icon={icon} size="3x" />
					<h4 className='my-auto text-uppercase m-0 fw-bold' style={styleText}>{title}</h4>
				</CardTitle>
			</CardHeader>
			<CardBody className="px-0">
				<table className='table '>
					<thead >
						<tr >
							{columnsRender.map((column) => (
								<th style={styles} key={'tab-head-' + column.label} onClick={() => requestSort(column.field)}>
									{column.label.toUpperCase()}

								</th>
							))}
						</tr>

					</thead>
					<tbody>
						{onCurrentPageData.map((item, index) => (
							<tr key={keyExtractor ? keyExtractor(item) : item.id}>
								{columnsRender.map((c, index2) => (
									<td style={styles} className='py-3 ' key={`tab-cell-${item.id}-${index}-${index2}`}>
										{c.format ? c.format(item) : item[c.field]}
									</td>
								))}
							</tr>
						))}

					</tbody>

				</table>
				{!isNaN(totalItems) &&
					<div className='d-flex justify-content-between px-4 pt-2 w-100'>
						<h1 className='text-uppercase fs-5 fw-bolder' style={styleTotal}>Total</h1>
						<p className='fs-5 fw-bold' style={styleTotal}>{Money.centsToMaskMoney(totalItems || 0)}</p>
					</div>
				}

			</CardBody>

		</Card>
	);
};

export default memo(TableCustomPdf);
