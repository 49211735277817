import React, { memo, useMemo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import TableCustom from '../../../components/MyCustom/TableCustom';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';


const TYPE_DEVICE = {
  POS: 0,
  TOTEM: 1,
  TOTEM_RECARGA: 2,
}

const RankingTerminal = ({ fatPos, maxLine }) => {

  const { darkModeStatus } = useDarkMode();

  const columns = useMemo(() => ([
    {
      label: '#',
      field: 'position',
    },
    {
      label: '',
      field: 'device',
      format: row => <Icon icon={
        row.device === TYPE_DEVICE.POS ? 'Smartphone' :
          row.device === TYPE_DEVICE.TOTEM ? 'SettingsCell' : 'Tv'
      } />,
      noExport: true,
    },
    {
      label: 'NOME',
      field: 'name',
    },
    {
      label: 'TOTAL',
      field: 'total',
      format: row => Money.centsToMaskMoney(row.total),
      formatExport: value => Money.centsToCoin(value),
    },
  ]), [])

  const rowsPos = useMemo(() =>
    fatPos.map(item => ({
      id: item.id,
      position: 0,
      device: TYPE_DEVICE.POS,
      name: item.operator,
      total: item.cashless,
    }))
  , [fatPos])

  const rows = useMemo(() => {
    rowsPos.sort((a, b) => {
      if (a.total > b.total) return -1;
      return 1;
    })
    rowsPos.forEach((item, index) => item.position = index + 1)
    return rowsPos;
  }, [rowsPos])

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='EmojiEvents' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              RANKING TERMINAIS CONSUMO
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Ranking de consumo </b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='row'>
        <div className='col-sm-12'>
          <TableCustom
            rows={rows}
            columns={columns}
            initPerPage={ maxLine?fatPos.length :6}
            fileName='Ranking-terminais-consumo'
          
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(RankingTerminal)
