import React, { memo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import GraphicDash from '../../../components/MyCustom/Dashboard/GraphicDash';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import { COLORS_DARK, COLORS_LIGHT } from '../../../types/dashColors';

const FatPosTotem = ({ faturamento, qtdPos, qtdTotems, }) => {

  const { darkModeStatus } = useDarkMode();

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              FATURAMENTO POS - TOTEM
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Relação de faturamento POS X TOTEM, quantidade de atendimento POS - TOTEM e POS - TOTEM iniciados no evento.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='row'>
        <div className='col-sm-12 col-xxl-6'>
          <GraphicDash
            labels={[
              'Pos',
              'Totem',
            ]}
            colors={[
              (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PRIMARY,
              (darkModeStatus ? COLORS_DARK : COLORS_LIGHT ).GREEN,
            ]} 
            series={[
              Money.centsToCoin(faturamento?.pos || 0),
              Money.centsToCoin(faturamento?.totem || 0),
            ]}
          />
        </div>

        <div className='col-xxl-6'>
          <div className='row'>
            <div className='col-md-6' >
              <CardValue
                color='dark'
                label='FATURAMENTO POS'
                description='Valor faturado com a POS no evento.'
                value={Money.centsToMaskMoney(faturamento?.pos || 0)}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='green'
                label='FATURAMENTO TOTEM'
                description='Valor faturado com TOTEM no evento.'
                value={Money.centsToMaskMoney(faturamento?.totem || 0)}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='primary'
                label='QTD ATEND. POS'
                description='Quantidade de atendimentos gerados no evento pelas POS..'
                value={faturamento?.qtdAttendancePos || 0}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='blue'
                label='QTD ATEND. TOTEM'
                description='Quantidade de atendimentos gerados no evento pelos totens.'
                value={faturamento?.qtdAttendanceTotem || 0}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='primary'
                label='QTD POS'
                description='Quantidade de POS no evento.'
                value={qtdPos}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='blue'
                label='QTD TOTEM'
                description='Quantidade de totens no evento.'
                value={qtdTotems}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(FatPosTotem)
