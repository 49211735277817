import React, { useState, useEffect, useContext, memo, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import Papa from 'papaparse'
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import EarlyConsummationDB from '../../../database/wrappers/earlyConsummation';
import { preparingDataCsvByImporList } from '../../../utils/import/importList';

const validate = (values) => {
  const errors = {};

  if (!values.fileUget && !values.fileIngresse) {
    errors.fileIngresse = 'Insira o arquivo em ao menos um dos campos';
    errors.fileUget = errors.fileIngresse;
  } 

  return errors;
}

const ModalRegisterEarlyConsummation = ({ open, setIsOpen, setIsClose }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [originalFile, setoriginalFile] = useState(null);

  useEffect(() => {
    if (!open) return formik.resetForm();
  }, [open])

  const handleFiles = e => {
    const [file] = Array.from(e.target.files)
    formik.setFieldValue(e.target.id, file)
    formik.setFieldValue(e.target.id + '_value', e.target.value)
  }

  const formik = useFormik({
    initialValues: {
      fileUget: '',
      fileUget_value: '',
      fileIngresse: '',
      fileIngresse_value: '',
    },
    validate,
    onSubmit: values => {
      const { fileUget, fileIngresse } = values;
      const onSuccess = () => {
        onShowAlert(PreAlert.success('Lista importada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error(error.message || 'Falhou ao importar a lista'))
      }

      async function saveData() {
        const file = fileUget || fileIngresse;
        const datas = await preparingDataCsvByImporList(file, file === fileUget ? 'UGET' : 'SYMPLA')
        return new EarlyConsummationDB(establishment.id, event.id).writeBatch(datas)
      }
      
      setIsLoading(true);
      try {
        saveData()
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
      } catch (error) {
        console.error(error);
        onError();
        setIsLoading(false)
      } 
    },
  })

  const file_valueSelected = useMemo(() => {
    return Object.keys(formik.values).find(key => key.includes('_value') && formik.values[key]) || ''
  },[formik.values])  
  
  return (
    <Modal
      id={'modal-register-EarlyConsummation'}
      titleId={'Cadastro e Update de Entrada Antecipada'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-EarlyConsummationSelected">Importando Consumação Antecipada</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* File */}
            <FormGroup id="fileUget" label="Arquivo padrão uGet .CSV" className='col-md-8'>
              <Input
                type='file'
                accept={'.csv'}
                onChange={handleFiles}
                onBlur={formik.handleBlur}
                value={formik.values.fileUget_value}
                isValid={formik.isValid}
                isTouched={formik.touched.fileUget}
                invalidFeedback={formik.errors.fileUget}
                disabled={file_valueSelected !== '' && file_valueSelected !== 'fileUget_value'}
                validFeedback='Assim está bom!'
                placeholder=''
              />
            </FormGroup>
            <FormGroup id="fileIngresse" label="Arquivo padrão Sympla .XLSX" className='col-md-8'>
              <Input
                type='file'
                accept={'.xlsx'}
                onChange={handleFiles}
                onBlur={formik.handleBlur}
                value={formik.values.fileIngresse_value}
                isValid={formik.isValid}
                isTouched={formik.touched.fileIngresse}
                invalidFeedback={formik.errors.fileIngresse}
                disabled={file_valueSelected !== '' && file_valueSelected !== 'fileIngresse_value'}
                validFeedback='Assim está bom!'
                placeholder=''
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterEarlyConsummation);