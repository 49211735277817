import React, { useCallback, useMemo, useContext, useEffect, useState, useRef } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import ModalRegisterProducts from '../../../../components/modals/Products/ModalRegister';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import ProductDB from '../../../../database/wrappers/product';
import Money from '../../../../helpers/utils/money/money'
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import Input from '../../../../components/bootstrap/forms/Input';
import ImportProductCsv from '../../../../utils/import/importProductCsv';
import PreAlert from '../../../../helpers/utils/preAlert';
import BtnLoad from '../../../../components/MyCustom/BtnLoad';
import useDarkMode from '../../../../hooks/useDarkMode';
import LogoUgetBlack from '../../../../assets/img/logo_uget_black.png'
import LogoUget from '../../../../assets/img/logo_uget.png'
import { Link } from 'react-router-dom';

const Products = () => {
  const { accessLevel, estabSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext)

  const { darkModeStatus } = useDarkMode();

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [products, setProducts] = useState([])
  const [productSelected, setProductSelected] = useState({});
  const [loadImport, setloadImport] = useState(false);

  const hasProductSingleSale = products.find(product => product.customizedPrice);

  const inputFile = useRef(null) 
  const refLinkProduct = useRef(null)

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new ProductDB(estabSelected).on(setProducts, orderBy('name', 'asc'));
    return onSubscribe;
  }, [estabSelected])

  const handleFiles = useCallback(e => {
    if(!estabSelected) return;
    setloadImport(true)
    const [file] = Array.from(e.target.files)
    new ImportProductCsv(estabSelected)
      .import(file)
      .then(() => onShowAlert(PreAlert.success('Registro(s) cadastrado(s) com sucesso')))
      .catch((error) => onShowAlert(PreAlert.error(error || 'Falhou ao cadastrar o(s) registro(s)')))
      .finally(() => setloadImport(false))
  },[estabSelected])

  const onClickImport = useCallback(() => {
    inputFile.current.click();
  }, [inputFile])

  const onDownloadProductCsv = useCallback(() => {
    refLinkProduct.current.click();
  }, [refLinkProduct])

  const handleOpenModalRegister = useCallback(() => {
    setProductSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    const productToEdit = {
      ...item,
      price: item.price.toFixed(2).toString(),
   
    }

    setProductSelected(productToEdit);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Produto',
      message: 'Você está excluindo um produto. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new ProductDB(estabSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const handleChangeStatus = useCallback((id, active) => {
    new ProductDB(estabSelected)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected])

  const rowsRender = useMemo(() => products.map(item => ({
    ...item,
    fiscal_bar_code: item.fiscal_data?.bar_code,
    fiscal_ncm: item.fiscal_data?.ncm,
    fiscal_cst: item.fiscal_data?.cst,
    fiscal_csosn: item.fiscal_data?.csosn,
    fiscal_cest: item.fiscal_data?.cest,
    fiscal_cfop: item.fiscal_data?.cfop,
    fiscal_aliq_icms: item.fiscal_data?.aliq_icms,
    fiscal_tributary_situation: item.fiscal_data?.tributary_situation,
  })), [products])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img 
          src={row.image_url || (darkModeStatus ? LogoUget : LogoUgetBlack)} 
          alt="Imagem" 
          width={54} 
          height={row.image_url ? 54 : 10} 
        />
      ),
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Preço',
      field: 'price',
      format: row => (
        <span>{Money.centsToMaskMoney(row.price)}</span>
      ),
      formatExport: value => Money.centsToCoin(value)
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row.id, row.active)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      )
    },
    {
      label: 'Estoque Composto',
      field: 'type_compound',
      format: row => (
        <>
          <Button
            isLink
            color={row.type_compound ? 'success' : 'warning'}
            icon={row.type_compound ? 'Check' : 'ShieldExclamation'}
            className='text-nowrap'>
            {row.type_compound ? 'Composto' : 'Simples'}
          </Button>
        </>
      ),
      formatExport: value => value ? 'Composto' : 'Simples'
    },
    {
      label: 'Cod. Barra',
      field: 'fiscal_bar_code',
      hidden: true
    },
    {
      label: 'NCM',
      field: 'fiscal_ncm',
      hidden: true
    },
    {
      label: 'CST',
      field: 'fiscal_cst',
      hidden: true
    },
    {
      label: 'CSOSN',
      field: 'fiscal_csosn',
      hidden: true
    },
    {
      label: 'CEST',
      field: 'fiscal_cest',
      hidden: true
    },
    {
      label: 'CFOP',
      field: 'fiscal_cfop',
      hidden: true
    },
    {
      label: 'ICMS',
      field: 'fiscal_aliq_icms',
      hidden: true
    },
    {
      label: 'SIT. Tributaria',
      field: 'fiscal_tributary_situation',
      hidden: true
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />

          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.product.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/pdv/products' },
                {
                  title: 'Produtos',
                  to: '/pdv/products',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <>
              <a hidden ref={refLinkProduct} href='https://firebasestorage.googleapis.com/v0/b/e-pdx-b9d3b.appspot.com/o/ExtraFiles%2Fprodutos.csv?alt=media&token=85a0cffe-1981-4d8f-a8ce-5aa555cf9520'/>
              <Button
                type='file'
                className='text-dark'
                color='success'
                icon='download'
                shadow="sm"
                hoverShadow="sm"
                onClick={onDownloadProductCsv}
              >
                Download CSV
              </Button>
              <Input
                hidden
                id='input-file-import'
                type='file'
                accept={'.csv'}
                ref={inputFile}
                onChange={handleFiles}
              />
              {loadImport ?
                <BtnLoad />
                :
                <Button
                  type='file'
                  className='text-dark'
                  color='warning'
                  icon='upload'
                  shadow="sm"
                  hoverShadow="sm"
                  onClick={onClickImport}
                >
                  Importar CSV
                </Button>
              }
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>
              </>}
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='TagsFill' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Produtos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />
              <TableCustom 
                titlePdf='Produtos'
                icon='TagsFill'
                columns={columns} 
                rows={rowsRender} 
                keyExtractor={item => item.id}
                fileName="Produtos"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterProducts
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        productSelected={productSelected}
        hasProductSingleSale={hasProductSingleSale}
      />
    </>
  );
};

export default Products;
