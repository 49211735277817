import * as XLSX from 'xlsx'

export async function importXlsxData<T>(headerLine: number, file: any): Promise<T[]> {
  const fileXlsx = XLSX.read(await file.arrayBuffer());
  const data = fileXlsx.Sheets[fileXlsx.SheetNames[0]];
  const alphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',]
  const header: string[] = [];
  alphabet.forEach(letter => {
    const value = data[letter + headerLine]?.v;
    if(value) {
      header.push(value);
    }
  })

  const keys = Object.keys(data);
  const lastLine = Number(keys[keys.length-3].replace(/\D/g, ""));
  if(isNaN(lastLine))
    throw new Error('Ultimo linha representa um número válido');

  let line = headerLine;
  const rows = [];
  while(++line < lastLine) {
    const row: any = {};
    alphabet.forEach((letter, colIndex) => {
      const value = data[letter + line]?.v;
      if(value) {
        row[header[colIndex]] = value;
      }
    })
    if(Object.keys(row).length > 0) {
      rows.push(row)
    }
  }
  
  return rows
}