import React, { memo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import GraphicDash from '../../../components/MyCustom/Dashboard/GraphicDash';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import { COLORS_DARK, COLORS_LIGHT } from '../../../types/dashColors';


const CartoesPulseira = ({cartoesPulseira}) => {
    const qtdCards = cartoesPulseira?.habilitado || 0;
    const homem =  cartoesPulseira?.homem || 0;
    const mulher = cartoesPulseira?.mulher || 0;
    const indefinido = cartoesPulseira?.indefinido || 0;
    // const courtesy = 0;



    const { darkModeStatus } = useDarkMode();

    return (
        <Card stretch>
            <CardHeader>
                <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                    <CardTitle className='d-flex flex-row align-items-center'>
                        <CardActions>
                          CARTÕES PULSEIRA
                        </CardActions>
                        <Popovers trigger={'hover'} desc={<b>Quantidade de cartões e pulseiras habilitados no evento.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='row'>
                <div className='col-sm-12 col-xxl-6'>
                    <GraphicDash
                        colors={[
                            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).ORANGE,
                            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PRIMARY,
                            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).SUCCESS,
                            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).GREEN,
                            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).INFO,
                            // (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PURPLE,
                        ]}
                        labels={[
                            'Homem',
                            'Mulher',
                            'Indefinido',
                            // 'Cortesia',
                        ]}
                        series={[
                            cartoesPulseira?.homem || 0,
                            cartoesPulseira?.mulher || 0,
                            cartoesPulseira?.indefinido || 0,
                            // cartoesPulseira?.courtesy || 0,
                        ]}
                    />
                </div>

                <div className='col-xxl-6'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <CardValue
                                color='dark'
                                label='TOTAL HABILITADOS'
                                description='Total de cartões / pulseiras habilitados, representados por homens, mulheres e indefinido.'
                                value={qtdCards || 0}
                            />
                        </div>
                        <div className='col-md-6'>
                            <CardValue
                                color='primary'
                                label='HOMEM'
                                description='Total de cartões / pulseiras habilitados como homens.'
                                value={Math.round(homem || 0)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <CardValue
                                color='success'
                                description='Total de cartões / pulseiras habilitados como mulheres.'
                                label='MULHER'
                                value={Math.round(mulher || 0)}
                            />
                        </div>
                        <div className='col-md-6'>
                            <CardValue
                                color='green'
                                description='Total de cartões / pulseiras habilitados como indefinido.'
                                label='INDEFINIDO'
                                value={Math.round(indefinido || 0)}
                            />
                        </div>
                        {/* <div className='col-md-6'>
                            <CardValue
                                color='purple'
                                description='Total de cartões / pulseiras habilitados como cortesia.'
                                label='CORTESIA'
                                value={Math.round(courtesy || 0)}
                            />
                        </div> */}
                    </div>
                </div>
            </CardBody>
        </Card >
    )
}

export default memo(CartoesPulseira)
