import React from 'react';
import TableCustomPdf from '../../MyCustom/TableCustomPdf';
import Card, { CardHeader, CardActions, CardBody, CardLabel, CardTitle } from '../../bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import GraphicDash from '../../MyCustom/Dashboard/GraphicDash';
import CardValue from '../../MyCustom/Dashboard/CardValue';
import CartoesPulseira from '../../../pages/financial/Geral/cartoesPulseira';
import QtdEtorno from '../../../pages/financial/Geral/qtdEtorno';
import RankingProducts from '../../../pages/financial/Geral/rankingProducts';
import RankingBilheteria from '../../../pages/financial/Geral/rankingBilheteria';
import RankingTerminalConsumo from '../../../pages/financial/Geral/rankingTerminalConsumo';
import FatPosTotem from '../../../pages/financial/Geral/fatPosTotem';



const ContentPdf = ({ data, graphics, cartoesPulseira, estorno, products,fatPosTotem,  rankingBilheteria, rankingTerminalConsumo }) => {



	return (
		<div className='p-2 px-3'>
			{graphics && graphics.map((item, index) => (

				<Card stretch className={index !== 0 ? "pagebreak" : ""}>
					<CardHeader>
						<CardLabel icon={item.icon} iconColor="dark" >
							<CardTitle className='d-flex flex-row align-items-center  text-uppercase '>
								<CardActions>
									{item.title}
								</CardActions>
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='row'>

						<div className='col-sm-6 col-xxl-6 col-lg-6'>
							<GraphicDash
								labels={item?.data?.filter(item => item.label !== 'Total').map(item => item.label)}
								series={item?.data?.filter(item => item.label !== 'Total').map(item => item.type !== 'number' ? Money.centsToCoin(item.value || 0) : item.value)}

							/>
						</div>
						<div className='col-sm-6 col-xxl-6 col-lg-6'>
							<div className='row' >
								{item.data && item.data.map((graphic) => (
									<div className={graphic.className || 'col-md-6'}>
										<CardValue
											shadow='none'
											color={graphic.color || 'warning'}
											label={graphic.label}
											value={item.type !== 'number' ? Money.centsToMaskMoney(graphic.value || 0) : graphic.value}
										/>
									</div>
								))}
							</div>
						</div>
					</CardBody>


				</Card >
			))}
			{cartoesPulseira && <CartoesPulseira cartoesPulseira={cartoesPulseira} />}
			{fatPosTotem && <FatPosTotem faturamento={fatPosTotem.faturamento} qtdPos={fatPosTotem.qtdPos} qtdTotems={fatPosTotem.qtdTotems} />}		
			{estorno && estorno && <QtdEtorno qtdEtorno={estorno.qtdEtorno} recarga={estorno.recarga} products={estorno.productsReversed} />}
			{products && products.length && <RankingProducts products={products} />}
			{rankingBilheteria && rankingBilheteria.entrances.length > 0 && <RankingBilheteria entrances={rankingBilheteria.entrances} entranceDicionary={rankingBilheteria.entranceDicionary} />}
			{rankingTerminalConsumo && rankingTerminalConsumo.fatPos.length > 0 && <RankingTerminalConsumo fatPos={rankingTerminalConsumo.fatPos} maxLine={true}/>}




			{data && data.map((item) => (
				<TableCustomPdf
					fontSize={item.fontSize}
					icon={item.icon}
					title={item.title}
					columns={item.columns.filter((c) => !c.noExport)}
					rows={item.data}
					keyExtractor={row => row.id}
				/>
			)
			)}
		</div>
	);

}

export default ContentPdf;