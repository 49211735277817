import React from "react";
import HeaderSector from "./header";
import ContentPdf from "./content";
import FooterPdf from "./footer";

const SectorPdf = ({ data, graphics, cartoesPulseira, fatPosTotem, estorno, products, rankingBilheteria, rankingTerminalConsumo }) => {

    return (
        <>
            <HeaderSector />
            <ContentPdf
                data={data}
                graphics={graphics}
                cartoesPulseira={cartoesPulseira}
                estorno={estorno}
                products={products}
                rankingBilheteria={rankingBilheteria}
                rankingTerminalConsumo={rankingTerminalConsumo}
                fatPosTotem={fatPosTotem}
            />
            <FooterPdf />
        </>
    );
}

export default SectorPdf;
