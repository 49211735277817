import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { Options } from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select';
import masks from '../../../helpers/utils/masks';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import { ACCESS_LEVEL, BASIC_ROLE, ROLE, ROLE_PTBR } from '../../../types/roles';
import { DefaultContext } from '../../../contexts/default';

import api from '../../../services/api';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnLoad from '../../MyCustom/BtnLoad';
import ListEstablishments from './ListEstablishments';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import EstablishmentDB from '../../../database/wrappers/establishment';

const ModalRegisterUsers = ({ open, setIsOpen, setIsClose, editData, loadUsers }) => {

  const { accessLevel, establishments, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  const validate = useCallback((values) => {
    const errors = {};
  
    if (!values.name) {
      errors.name = 'Este campo é necessário';
    } else if (values.name.length < 3) {
      errors.name = 'O nome precisa ter 3 caracteres ou mais';
    }
  
    if(!editData) {
      if (!values.cpf) {
        errors.cpf = 'Este campo é necessário';
      } else if (values.cpf.length < 14) {
        errors.cpf = 'Informe o cpf completo';
      }
    }
  
    if (!values.active) {
      errors.active = 'Você precisa escolher o status da categoria';
    }
  
    if (!values.role) {
      errors.role = 'Este campo é necessário';
    } else if(values.role === BASIC_ROLE.MANAGER && values.estabs.length === 0) {
      errors.estabs = 'Selecione ao menos um estabelecimento';
    }
  
    if (!values.email) {
      errors.email = 'Este campo é necessário.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email inválido.';
    }
  
    if(!editData) {
      if (!values.password || values.password.length < 6) {
        errors.password = 'A senha precisa ter 6 caracteres ou mais';
      }
    }
  
    return errors;
  },[editData])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      // cardNumber: '',
      active: '',
      role: '',
      email: '',
      password: '',
      estabs: [],
    },
    validate,
    onSubmit: async values => {
      const { name, active, cpf, phone, role, email, password, estabs } = values;

      const data = {
        name,
        email,
        cpf: Number(cpf?.replace(/\D/g, "") || 0),
        phone: Number(phone?.replace(/\D/g, "") || 0),
        // cardNumber,
        role,
        estabs,
        password,
        disabled: active === 'inativo',
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        loadUsers();
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        loadUsers();
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      const checkUserExists = async (email) => {
        try {
          const response = await api.get(`routes/users?email=${email}`);
          const user = response.data.find(user => user.email === email);
          if (user) {
            return user;
          }
          return null;
        } catch (error) {
          console.error('falha', error);
          throw error;
        }
      };


      try {
        if (editData) {
          data.uid = editData.uid;
          await api.put('routes/users', data);
          onSuccessUpdate();
        } else {
          const existingUser = await checkUserExists(email);
          if (existingUser) {
            setIsLoading(false);    
            const userEstabId = existingUser.customClaims?.estabs[0];
            if (userEstabId) {
              const estabD = new EstablishmentDB();
              const estabDoc = await estabD.getById(userEstabId);
              if (estabDoc) {
                const estabName = estabDoc.name;
                console.log('estabelecimento', estabName )
                onShowAlert(PreAlert.error(`Usuário já existe associado ao estabelecimento "${estabName}"`));
              } else {
                console.log("Estabelecimento não encontrado com o ID:", userEstabId);
                onShowAlert(PreAlert.error("Usuário já existe, mas o estabelecimento associado não foi encontrado"));
              }
            } else {
              onShowAlert(PreAlert.error("Usuário já existe, mas o estabelecimento associado não foi especificado"));
            }
          } else {
            await api.post('routes/users', data);
            onSuccess();
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Falha ao verificar se o usuário existe:', error);
        onShowAlert(PreAlert.error('Falhou ao verificar se o usuário existe'));
      }
    }
  });
  

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (editData) {
      const {
        phone,
        cpf,
        email,
        displayName,
        disabled,
        customClaims,
      } = editData;
      formik.setValues({
        name: displayName,
        active: disabled ? 'inativo' : 'ativo',
        role: customClaims?.role,
        email,
        phone: masks.phone(String(phone)),
        cpf: masks.cpf(String(cpf)),
        estabs: customClaims?.estabs || [],
      });
    }
  }, [editData, open])

  const rolesOptions = useMemo(() => {
    const ROLE_COPY = structuredClone(BASIC_ROLE)

    delete ROLE_COPY.OPERATOR
    // delete ROLE_COPY.COORDINATOR
    if (accessLevel < ACCESS_LEVEL.ADMIN)
      delete ROLE_COPY.ADMIN;
    if (accessLevel < ACCESS_LEVEL.MANAGER)
      delete ROLE_COPY.MANAGER;

    const options = Object.keys(ROLE_COPY).map((key) => ({
      text: ROLE_PTBR[ROLE_COPY[key]],
      value: ROLE_COPY[key]
    }))
    options.unshift({ text: 'Selecione uma função', value: '' })
    return options;
  }, [accessLevel])

  const showSelectEstab = useMemo(() =>
    formik.values.role === BASIC_ROLE.MANAGER || formik.values.role === BASIC_ROLE.STOCKIST
  , [formik.values.role])

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">{editData ? 'Atualização de Usuário' : 'Cadastro de Usuário'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: João da Silva'
              />
            </FormGroup>

            {/* role */}
            <FormGroup id='role' label='Função' className='col-md-4' >
              <Select
                style={{ cursor: 'pointer' }}
                id="role"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                isTouched={formik.touched.role}
                invalidFeedback={formik.errors.role}
                value={formik.values.role}
              >
                <Options
                  list={rolesOptions}
                />
              </Select>
            </FormGroup>

            {/* Email */}
            <FormGroup id="email" label="Email" className='col-md-4'>
              <InputGroup>
                <InputGroupText id="inputGroupPrepend">
                  @
                </InputGroupText>
                <Input
                  id="email"
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                  validFeedback='Assim está bom!'
                  placeholder="joaodasilva@email.com"
                  disabled={editData !== null}
                />
              </InputGroup>
            </FormGroup>

            {/* cpf */}
            <FormGroup id='cpf' label='CPF' className='col-md-4'>
              <Input
                type="cpf"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.cpf(formik.values.cpf)}
                isValid={formik.isValid}
                isTouched={formik.touched.cpf}
                invalidFeedback={formik.errors.cpf}
                validFeedback='Assim está bom!'
                placeholder='CPF do Usuário'
                disabled={editData !== null}
              />
            </FormGroup>

            {/* phone */}
            <FormGroup id='phone' label='Telefone' className='col-md-4'>
              <Input
                type="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.phone(formik.values.phone)}
                isValid={formik.isValid}
                isTouched={formik.touched.phone}
                invalidFeedback={formik.errors.phone}
                validFeedback='Assim está bom!'
                placeholder='Telefone do Usuário'
                disabled={editData !== null}
              />
            </FormGroup>

            {/* Card Number */}
            {/* <FormGroup id="cardNumber" label="N Cartão" className='col-md-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cardNumber}
                isValid={formik.isValid}
                isTouched={formik.touched.cardNumber}
                invalidFeedback={formik.errors.cardNumber}
                validFeedback='Assim está bom!'
                placeholder='Ex: 01234'
              />
            </FormGroup> */}

            {/* Password */}
            <FormGroup id="password" label="Senha" className='col-md-4'>
              <Input
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='Assim está bom!'
                placeholder='Ex: 01234'
                disabled={editData !== null}
              />
            </FormGroup>

            {showSelectEstab && <h6>Estabelecimentos</h6>}

            <ListEstablishments
              hidden={!showSelectEstab}
              establishment={establishments}
              establishmentSelected={formik.values.estabs}
              onChange={formik.handleChange}
            />

            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterUsers;