import Papa from 'papaparse'
import ImportListUget from '../../interface/importListUget'
import EarlyConsummation from '../../database/entities/event/earlyConsummation.entity'
import { importXlsxData } from './importXlsx'
import ImportListSympla from '../../interface/importListSympla'

function validateList(rows: EarlyConsummation[]) {
  rows.forEach((row, index) => {
    if (row.email === null && row.cpf === null)
      throw new Error(`Linha ${index + 1}. Sem identificação, email ou cpf`)
    if (typeof row.value !== 'number' || isNaN(row.value))
      throw new Error(`Linha ${index + 1}. Campo valor não é um número válido`)
    if (typeof row.consummation !== 'number' || isNaN(row.consummation))
      throw new Error(`Linha ${index + 1}. Campo consumação não é um número válido`)
  })
}

function readCSVuGet(csv: string): EarlyConsummation[] {
  const results = Papa.parse<ImportListUget>(csv, { header: true }) // object with { data, errors, meta }
  const rows = results.data.filter((row) => Object.keys(row).length !== 1) // array of objects
  const objectKeys = Object.keys(rows[0]);
  if (['Email Portador', 'CPF', 'Valor', 'Consumacao'].filter(e => !objectKeys.includes(e)).length !== 0)
    throw new Error('Este arquivo não corresponde ao padrão uGet')

  const datas: EarlyConsummation[] = rows.map<any>(row => ({
    event_id_ingresse: row['ID Evento'] === undefined ? null : row['ID Evento'],
    event_ingresse: row['Evento'] === undefined ? null : row['Evento'],
    name: row['Nome Portador'] === undefined ? null : row['Nome Portador'],
    email: row['Email Portador'] === undefined ? null : row['Email Portador'],
    phone: row['Telefone'] === undefined ? null : Number(row['Telefone'].toString().replace(/\D/g, "")),
    cpf: row['CPF'] === undefined ? null : Number(row['CPF'].toString().replace(/\D/g, "")),
    ticket: row['Ingresso'] === undefined ? null : row['Ingresso'],
    value: row['Valor'] === undefined ? null : Math.round(Number(row.Valor) * 100),
    consummation: row['Consumacao'] === undefined ? null : Math.round(Number(row.Consumacao) * 100),
    used: false,
  }))

  validateList(datas as any)

  return datas
}

async function readXlsSympla(file: any) {
  const rowsXlsx = await importXlsxData<ImportListSympla>(8, file)
  const linesWithoutGarbage = rowsXlsx.filter(row => Object.keys(row).find(key => key === 'Tipo de ingresso'))
  const datas: EarlyConsummation[] = linesWithoutGarbage.map<any>(row => ({
    event_id_ingresse: 0,
    event_ingresse: 'Sympla',
    name: `${row['Nome']} ${row['Sobrenome'] || ''}`,
    email: row['Email'] === undefined ? null : row['Email'],
    phone: null,
    cpf: null,
    ticket: row['Tipo de ingresso'] === undefined ? null : row['Tipo de ingresso'],
    value: row['Consumação'] === undefined ? null : Math.round(Number(row['Consumação']) * 100),
    consummation: row['Consumação'] === undefined ? null : Math.round(Number(row['Consumação']) * 100),
    used: false,
  }))
  validateList(datas)
  return datas;
}

function startImportCsvUget(file: any): Promise<any> {
  return new Promise<EarlyConsummation[]>((res, rej) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      if (typeof reader.result !== 'string') {
        return rej('Falhou ao carregar o arquivo CSV')
      }
      try {
        const datas = readCSVuGet(reader.result);
        res(datas)
      } catch (error) {
        rej(error)
      }
    }
    reader.readAsText(file);
  })
}

function startImportXlsxSympla(file: any): Promise<any> {
  return new Promise<EarlyConsummation[]>(async (res, rej) => {
    try {
      const datas = await readXlsSympla(file);
      res(datas as any)
    } catch (error) {
      rej(error)
    }
  })
}

export function preparingDataCsvByImporList(file: any, type: 'UGET' | 'SYMPLA'): Promise<any> {
  if (type === 'UGET') {
    return startImportCsvUget(file)
  } else {
    return startImportXlsxSympla(file)
  }
}