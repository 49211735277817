import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterOperators from '../../../../components/modals/Operators/modalRegister'
import ModalEditOperators from '../../../../components/modals/Operators/modalEdit'
import ModalQrcodeOperators from '../../../../components/modals/Operators/modalQrcode'
import ModalRegisterUniqueOperator from '../../../../components/modals/Operators/modalRegisterUnique'
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import OperatorsDB from '../../../../database/wrappers/operatorsEvent';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import { ROLE_PTBR } from '../../../../types/roles';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import SectorDB from '../../../../database/wrappers/sector';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import useDarkMode from '../../../../hooks/useDarkMode';
import UserOperatorsDB from '../../../../database/wrappers/usersOperators';
import { orderBy, where } from 'firebase/firestore';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
import QRcodeLoginEventDB from '../../../../database/wrappers/qRcodeLoginEvent';
import { REQUEST_REGISTER_TYPE } from '../../../../types/requestRegister';
import modalRegisterUnique from '../../../../components/modals/Operators/modalRegisterUnique';

const Operators = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext)

  const [registerUniqueModal, setregisterUniqueModal] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [sectorDicionary, setsectorDicionary] = useState({})
  const [operators, setoperators] = useState([])
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false);
  const [operatorSelected, setoperatorSelected] = useState(null);
  const [usersOperators, setusersOperators] = useState([])
  const [usersOperatorsDicionary, setusersOperatorsDicionary] = useState({})
  const [qrcodeDicionary, setqrcodeDicionary] = useState({})
  const [qrcodeSelected, setqrcodeSelected] = useState(null)

  useEffect(() => {
    return new UserOperatorsDB().on(datas => {
      setusersOperators(datas)
      setusersOperatorsDicionary(convertArrayToObject(datas, 'id'))
    }, orderBy('name', 'asc'))
  }, [])

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscriber = new SectorDB(estabSelected).on(datas => setsectorDicionary(convertArrayToObject(datas, 'id')))
    return onSubscriber;
  }, [estabSelected])

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new OperatorsDB(estabSelected, eventSelected).on(setoperators)
    const onSubscriber2 = new QRcodeLoginEventDB(estabSelected, eventSelected).on(datas => {
      const dicionary = convertArrayToObject(datas, 'id')
      setqrcodeDicionary(dicionary);
    })
    return () => {
      onSubscriber();
      onSubscriber2();
    }
  }, [estabSelected, eventSelected])

  const toggleRegisterUniqueModal = useCallback(() => {
    setregisterUniqueModal(state => !state)
  }, [])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleCloseModalEdit = useCallback(() => {
    setOpenModalEdit(false)
  }, [])

  const handleRegister = useCallback(() => {
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setoperatorSelected(item)
    setOpenModalEdit(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Produto',
      message: 'Você está excluindo um produto. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new OperatorsDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])

  const handleChangeStatus = useCallback((row) => {
    if (row.requestRegister === REQUEST_REGISTER_TYPE.WAITING_FOR_ANSWER)
      return;
    new OperatorsDB(estabSelected, eventSelected)
      .update(row.id, {
        active: !row.active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected, eventSelected])

  const handleChangeRequestRegister = useCallback((row) => {
    if (row.requestRegister === undefined)
      return;
    new OperatorsDB(estabSelected, eventSelected)
      .update(row.id, {
        requestRegister: row.requestRegister !== REQUEST_REGISTER_TYPE.ACCEPT
          ? REQUEST_REGISTER_TYPE.ACCEPT
          : REQUEST_REGISTER_TYPE.REJECT
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected, eventSelected])

  const showQrcode = useCallback((qrcode, item) => {
    setqrcodeSelected(qrcode)
    setoperatorSelected(item)
    setOpenModalQrcode(true)
  }, [qrcodeDicionary])

  


  const renderRows = useMemo(() =>
    (!usersOperatorsDicionary || !sectorDicionary) ? [] :
      operators.map(operator => ({
        ...operator,
        name: usersOperatorsDicionary[operator.id]?.name || operator.name,
        cpf: cpfFormatMask(usersOperatorsDicionary[operator.id]?.cpf.toString() || operator.cpf) ,
        role_ptbr: ROLE_PTBR[operator.role],
        sectorName: sectorDicionary[operator.sectorId]?.name,
        loginLabel: (operator.requestRegister === undefined || operator.requestRegister === REQUEST_REGISTER_TYPE.ACCEPT) && operator.active,
      }))
    , [usersOperatorsDicionary, sectorDicionary, operators])

  const columns = useMemo(() => ([
    {
      label: 'QRcode',
      field: 'id',
      format: row => (
        <div>
          {qrcodeDicionary[row.id] &&
            <Button
              className='btn-tablecustom-row'
              color={darkModeStatus ? 'light' : 'dark'}
              icon="QrCodeScanner"
              shadow="sm"
              hoverShadow="sm"
              size="sm"
              onClick={() => showQrcode(qrcodeDicionary[row.id], row)}
            />}
        </div>
      ),
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Cpf',
      field: 'cpf',
    },
    {
      label: 'Função',
      field: 'role_ptbr',
    },
    {
      label: 'Setor',
      field: 'sectorName',
    },
    {
      label: 'Solicitação',
      field: 'requestRegister',
      format: row => (
        row.requestRegister === undefined ? null
          :
          <Button
            isLink
            color={
              row.requestRegister === REQUEST_REGISTER_TYPE.REJECT ? 'danger' :
                row.requestRegister === REQUEST_REGISTER_TYPE.WAITING_FOR_ANSWER ? 'warning' : 'success'
            }
            icon={
              row.requestRegister === REQUEST_REGISTER_TYPE.REJECT ? 'ErrorOutline' :
                row.requestRegister === REQUEST_REGISTER_TYPE.WAITING_FOR_ANSWER ? 'WarningAmber' : 'Check'
            }
            onClick={() => handleChangeRequestRegister(row)}
            className='text-nowrap'
          >
            {
              row.requestRegister === REQUEST_REGISTER_TYPE.REJECT ? 'Rejeitado' :
                row.requestRegister === REQUEST_REGISTER_TYPE.WAITING_FOR_ANSWER ? 'Aguardando' : 'Aprovado'
            }
          </Button>
      ),
    },
    {
      label: 'Login',
      field: 'loginLabel',
      format: row => (
        <Button
          isLink
          color={row.loginLabel ? 'success' : 'danger'}
          icon={row.loginLabel ? 'Check' : 'Lock'}
          onClick={() => handleChangeStatus(row)}
          className='text-nowrap'
        >
          {row.loginLabel ? 'Liberado' : 'Bloqueado'}
        </Button>
      ),
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            className='btn-tablecustom-row'
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            className='btn-tablecustom-row'
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, qrcodeDicionary, handleChangeStatus, handleChangeRequestRegister,modalRegisterUnique])

 

  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.operators.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event' },
                { title: 'Operadores', to: '/event/operators' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              className='text-dark'
              color='warning'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={toggleRegisterUniqueModal}
            >
              Cadastro Único
            </Button>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='SupervisorAccount' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Operadores Por Evento</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>
              <TableCustom
                rows={renderRows}
                columns={columns}
                keyExtractor={row => row.id}
                fileName='Operadores-por-evento'
              />
            </CardBody>
          </Card>
        </Page>

        <ModalRegisterOperators
          open={openModalRegister}
          setIsOpen={setOpenModalRegister}
          setIsClose={handleCloseModal}
          operatorsDatas={operators}
          usersOperators={usersOperators}
        />

        <ModalEditOperators
          open={openModalEdit}
          setIsOpen={setOpenModalEdit}
          setIsClose={handleCloseModalEdit}
          operatorSelected={operatorSelected}
          usersOperatorsDicionary={usersOperatorsDicionary}
        />

        <ModalQrcodeOperators
          open={openModalQrcode}
          setIsOpen={setOpenModalQrcode}
          operatorSelected={operatorSelected}
          qrcodeData={qrcodeSelected}
          usersOperatorsDicionary={usersOperatorsDicionary}
        />

        <ModalRegisterUniqueOperator
          open={registerUniqueModal}
          setIsOpen={setregisterUniqueModal}
          setIsClose={toggleRegisterUniqueModal}
          operators={setoperators}
        />
      </PageWrapper>
    </>
  );
};

export default Operators;
